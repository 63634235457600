document.addEventListener('DOMContentLoaded', function() {

    var mobileAnchorTags = document.querySelectorAll('#offcanvasRight a');
    var offcanvasElement = document.getElementById('offcanvasRight');
    var offcanvas = new bootstrap.Offcanvas(offcanvasElement);
    

    // Get all li elements with class menu-item-has-children
    var menuItems = document.querySelectorAll('#MainNavigation .menu-item-has-children');

    Array.prototype.forEach.call(menuItems, function(menuItem) {
        // Get the ul with class desktop-sub-menu inside each li
        var submenu = menuItem.querySelector('.desktop-sub-menu');

        // Add event listener for mouse enter
        menuItem.addEventListener('mouseenter', function() {
            submenu.classList.add('show');
        });

        // Add event listener for mouse leave
        menuItem.addEventListener('mouseleave', function() {
            submenu.classList.remove('show');
        });

        // Add event listener for focusin (focus event bubbles)
        menuItem.addEventListener('focusin', function(event) {
            submenu.classList.add('show');
        });

        // Add event listener for focusout (blur event bubbles)
        menuItem.addEventListener('focusout', function(event) {
            var nextMenuItem = event.relatedTarget;
            if (!nextMenuItem || !menuItem.contains(nextMenuItem)) {
                submenu.classList.remove('show');
            }
        });
    });

    // Get all li elements with class menu-item-has-children
    var mobileMenuItems = document.querySelectorAll('#offcanvasRight .menu-item-has-children');

    Array.prototype.forEach.call(mobileMenuItems, function(menuItem) {
        // Get the ul with class desktop-sub-menu inside each li
        var submenu = menuItem.querySelector('.mobile-sub-menu');

        // Add event listener for mouse enter
        menuItem.addEventListener('click', function() {
            
            if(menuItem.classList.contains('active')){
                menuItem.classList.remove('active');
                submenu.style.height = "0px";
                submenu.addEventListener('transitionend', function() {
                    submenu.classList.remove('show');
                }, {once: true})
                
            } else {
                
                submenu.classList.add('show');
                menuItem.classList.add('active');
                submenu.style.height = "auto";

                var height = submenu.clientHeight + "px";

                submenu.style.height = "0px";

                setTimeout(function() {
                    submenu.style.height = height;
                }, 0);

            }

        });

    });

    function checkWindowSize() {
        // Check screen size
        if (window.innerWidth > 991) {
            offcanvas.hide();
        } 
    }
    window.addEventListener('resize', checkWindowSize);

    // Loop through each anchor tag
    Array.prototype.forEach.call(mobileAnchorTags, function(anchor) {
        // Get the href attribute of the anchor
        var href = anchor.getAttribute('href');
        
        var ClassCheck = anchor.classList.contains("mobile-menu-open-sub-menu");

        //if this menu item does not open a sub menu in the mobile menu
        if(!ClassCheck){
            // Check if href includes '#' and if the URL without hash is the same as the current page URL
            if (href.includes('#') && new URL(href, window.location.href).href.split('#')[0] === window.location.href.split('#')[0]) {
                // Add click event listener
                anchor.addEventListener('click', function(event) {
                    // Prevent default anchor behavior (jumping to top)
                    event.preventDefault();
        
                    offcanvas.hide();
        
                    // Get the hash part of the URL (excluding the #)
                    var hash = new URL(href, window.location.href).hash.substring(1);
        
                    // Set a timeout to scroll to the section after 500ms
                    setTimeout(function() {
                        // Find the target element using the hash
                        var targetElement = document.getElementById(hash);
                        if (targetElement) {
                            // Scroll to the target element
                            targetElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    }, 500);
                });
            }
        }
    });
});
